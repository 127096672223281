
.popup-user-register {
    position: fixed;
    top: 80px;
    z-index: 9;
    .card {
      background: #fff;
      border-radius: 15px;
      border: solid 1px blue;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 5px;
      margin: 8px;
      width: 200px;
      opacity: 0;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .card-text {
      flex: 1;
    }
    .card-content-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
    }
    .card-title {
      font-size: 0.8em;
      margin-bottom: 2px;
    }
  
    .card-content {
      font-size: 0.65em;
      line-height: 1.1em;
      color: #333;
    }
  
    .card-logo {
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
      margin-right: 10px;
      border-radius: 10px;
    }
    .card.show {
      opacity: 1; /* Fully visible */
      animation: fadeInFromNone 2s ease-out; /* Apply fade-in animation */
    }
  
    .card.hide {
      animation: fadeOutToNone 2s ease-in; /* Apply fade-out animation */
    }
    @keyframes fadeInFromNone {
      from {
        opacity: 0;
        transform: translateY(-20px); /* Optionally animate movement */
      }
      to {
        opacity: 1;
        transform: translateY(0); /* Optionally animate movement */
      }
    }
  
    @keyframes fadeOutToNone {
      from {
        opacity: 1;
        transform: translateY(0); /* Optionally animate movement */
      }
      to {
        opacity: 0;
        transform: translateY(-20px); /* Optionally animate movement */
      }
    }
    @media screen and (max-width: 768px) {
      .card-title {
        font-size: 0.8em;
        margin-bottom: 3px;
      }
  
      .card-content {
        font-size: 0.6em;
        line-height: 1em;
        color: #333;
      }
    }
    .card {
      min-width: 150px;
    }
  
    .card.show {
      opacity: 1;
      transform: translateY(0);
    }
  
    .card.hide {
      opacity: 0;
      transform: translateY(-20px);
    }
  
    @media screen and (max-width: 768px) {
      .card-title {
        font-size: 0.8em;
        margin-bottom: 3px;
      }
  
      .card-content {
        font-size: 0.6em;
        line-height: 1em;
        color: #333;
      }
    }
  }
  