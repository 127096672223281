.tfs-close-modal {
  position: absolute;

  left: -35px;
  top: 8px;
  color: white !important;
  font-size: 18px !important;
}

.bbl {
  $purpleLight: #ed188e;
  $purpleLigh2: #f6bfe9;
  $purpleDark: #aa15ff;
  $line-headline: #ed188e;
  min-height: 100vh;

  .online-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: row;
  }

  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bolder;
    font-size: 1.6em;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    pointer-events: none;
  }

  .flower-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .flower-petal {
    width: 180px;
    height: 180px;
    max-width: fit-content;
  }

  .center-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    max-width: fit-content;
  }

  .parent-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .card-welcome {
    background: linear-gradient(to bottom, #f6bfe9, white);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 40px;
    padding: 10px;
  }

  .questionaire-button {
    margin: 10px 20px;
    width: 20%;
    font-size: 1em;
    color: white;
    background: linear-gradient(to right, #ff89fe, rgb(203, 108, 230));
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    text-decoration: none;
    /* Remove underline from link */
  }

  .questionaire-button:hover {
    background: linear-gradient(to right, rgb(203, 108, 230), #ff89fe);
  }

  .card-promo {
    background: linear-gradient(to bottom, #f6bfe9, white);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 80%;
    margin: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 600px;
  }

  .card-promo:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .card-promo-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .card-promo-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }

  .card-promo-title {
    font-size: 1.6em;
  }

  .card-promo-description {
    font-size: 1em;
    color: #555;
    margin-bottom: 20px;
  }

  .card-promo-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .card-promo-button:hover {
    background-color: #0056b3;
  }

  .video-container iframe {
    width: 380px;
    height: 250px;
    border: 0;
  }

  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  img {
    width: 100%;
  }

  .blur-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.7);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #333;
    text-align: center;
  }

  .blur-cover p {
    margin-bottom: 20px;
    /* Add some space between the paragraph and the button */
  }

  .sign-in-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    color: white;
    background: linear-gradient(to right, #ff89fe, rgb(203, 108, 230));
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }

  .sign-in-button:hover {
    background: linear-gradient(to right, rgb(203, 108, 230), #ff89fe);
  }

  .section-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
    // background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: content-box;
    background-position: 50% 100%;
    background-attachment: scroll;
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent, rgb(65, 65, 66) 99%);
  }

  @mixin headline {
    // font-family: "Montserrat", "Arial", sans-serif;
    font-family: "Times New Roman", Times, serif;
    text-transform: uppercase;
    text-align: center;

    // white-space: nowrap;
    --color: #fff;
    --color-accent-1: hsl(50, 100%, 79%);
    --color-accent-2: hsl(313, 100%, 39%);
    // filter: drop-shadow(0.15em 0.1em 0.3em var(--color-accent-2));
    color: var(--color);
    font-weight: 800;
    // text-shadow: 4px 0 10px rgba(0, 0, 0, 0.13);
    background: -webkit-linear-gradient(#ed188e, rgb(203, 108, 230));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .headline-h1 {
    @include headline();
    font-size: 48px;
  }

  .headline-h2 {
    @include headline();
    font-size: 32px;
  }

  .headline-h3 {
    @include headline();
    font-size: 28px;
  }

  .headline-h4 {
    @include headline();
    font-size: 22px;
  }

  .bbl-banner {
    position: relative;
    height: 100vh;

    .parallax-banner {
      /* Adjust height as needed */
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .bbl-group {
      padding: 40px;
      border-radius: 20px;
    }

    // .bbl-banner-content {
    //   z-index: 99;
    // }
    .bbl-banner-img {
      position: absolute;
      bottom: 0;
      right: 10%;
      z-index: 98;
      width: 400px;
    }
  }

  .bbl-header {
    z-index: 999;

    .bbl-header-menu {
      background-color: rgba(224, 197, 232, 1);
    }
  }

  .header-description {
    color: rgb(94, 94, 94);
    text-align: center;
    padding: 0 40px;
  }

  .bbl-trainer {
    .img-trainer {
      border: 10px solid rgba(224, 197, 232, 1);
    }

    .bbl-trainer-info {
      .headline-h4 {
        text-align: left;
        position: relative;
        margin-bottom: 40px;

        &::after {
          content: "";
          position: absolute;
          bottom: -20px;
          left: 0;
          width: 150px;
          height: 3px;
          background-color: $line-headline;
        }
      }
    }
  }

  .bbl-trainer-coach {
    .bbl-trainer-coach-card {
      padding: 35px;

      div {
        color: white;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        background-color: rgb(224, 197, 232);
        border-radius: 20px;
        text-align: center;
      }

      .bbl-trainer-coach-img {
        border-radius: 20px;
        height: 350px;
      }

      &:nth-child(3n + 2) {
        top: -50px;
      }

      .bbl-trainer-coach-text {
        padding: 20px;
      }
    }

    .headline-h4 {
      text-align: left;
      position: relative;
      width: 350px;
      margin-bottom: 50px;

      &::after {
        content: "";
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 150px;
        height: 3px;
        background-color: $line-headline;
      }
    }
  }

  .bbl-group {
    position: relative;
    height: 100%;
  }

  .bbl-testimonial {
    .headline-h1 {
      position: relative;
      margin-top: 50px;
      margin-bottom: 40px;

      &::after {
        position: absolute;
        content: "";
        bottom: -10px;
        width: 150px;
        height: 3px;
        background: $line-headline;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .bbl-testimonial-cards {
      display: flex;
      margin-bottom: 30px;

      img {
        width: 150px;
        height: 150px;
      }

      .bbl-testimonial-content {
        padding: 20px;

        .bbl-testimonial-name {
          font-size: 18px;
          line-height: 22px;
          font-weight: bold;
          margin-bottom: 15px;
        }
      }
    }

    .bbl-testimonial-cards li {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 14rem;
      height: 18rem;
      text-align: center;
      line-height: 18px;
      font-size: 18px;
      font-family: sans-serif;
      background-color: #9d7cce;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0.8rem;
    }
  }

  .price-default-online {
    padding-top: 10px;
    font-size: 1.8rem;
    line-height: 28px;
    text-decoration: line-through;
    color: red;
    font-weight: bold;
    margin-right: 15px;
    margin-top: 4px;
  }

  .price-promo-online {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 2.8rem;
    font-weight: bolder;
    line-height: 34px;
    color: grey;
    font-weight: bold;
    animation: jump 2s infinite;
  }

  @keyframes jump {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .bbl-service {
    .bbl-service-cards-container {
      display: flex;
      /* Make the row a flex container */
      flex-wrap: wrap;
      /* Allow wrapping for responsive behavior */
      align-items: stretch;
      /* Ensure columns are stretched to equal height */
    }

    .bbl-service-call-to-action {
      margin-top: 30px;
      text-align: center;
      font-size: 26px;
      line-height: 30px;
      color: red;
      font-weight: bold;
    }

    .bbl-service-cards {
      display: flex;
      /* Make the service card a flex container */
      flex-direction: column;
      /* Align content vertically */
      flex: 1;
      /* Allow the card to grow and take the full height */
      // border: 1px solid #ddd; /* Optional: Add border for better visibility */
      padding: 10px;
      /* Optional: Add some padding */

      .bbl-service-content {
        flex: 1;
        /* Ensure content fills the available space */
        // display: flex; /* Use Flexbox for content alignment */
        flex-direction: column;
        /* Align items vertically */
        justify-content: space-between;
        /* Distribute space evenly */
        transform: translateY(-100px);
        margin: 0 40px;
        top: 40px;
        border-radius: 20px;
        padding: 15px 30px;
        background-image: linear-gradient(#a48ed2, $purpleLigh2);

        .headline-h3,
        .headline-h4 {
          background-image: linear-gradient(black, black);
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          text-shadow: -2px -2px white;
          padding: 15px;
        }

        .promo-text {
          @include headline();
          font-size: 18px;
          text-transform: unset;
        }

        .box-price {
          border-radius: 20px;
          border: 1px solid white;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 20px;
          align-self: stretch;
        }

        ul {
          padding-left: 0;
          list-style-type: none;

          li {
            font-weight: bold;
          }
        }

        .price-default {
          font-size: 26px;
          line-height: 28px;
          text-decoration: line-through;
          color: red;
          font-weight: bold;
          margin-right: 15px;
          margin-top: 4px;
        }

        .price-promo {
          font-size: 32px;
          line-height: 34px;
          color: white;
          font-weight: bold;
        }
      }
    }
  }

  .register-button {
    transform: translateY(-50px);
    border: 2px black solid;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: white;
    text-shadow: 3px 3px 3px black;
    background-image: linear-gradient(to right, #f677f6, #d128d3, #f677f6);
    font-size: 24px;
    line-height: 28px;
    border-radius: 40px;
    padding: 15px 30px;

    &:hover {
      opacity: 0.5;
    }
  }

  .bbl-media-infor {
    .headline-h1 {
      position: relative;
      background-image: linear-gradient(rgb(203, 108, 230));

      &::before {
        content: "";
        position: absolute;
        top: -20px;
        left: 50px;
        width: 75px;
        height: 25px;
        background-color: rgb(203, 108, 230);
      }

      &::after {
        content: "";
        position: absolute;
        top: -20px;
        left: 50px;
        width: 25px;
        height: 75px;
        background-color: rgb(203, 108, 230);
      }
    }

    .headline-h3 {
      text-align: left;
      background-image: linear-gradient(black, black);
    }

    .bbl-media-infor-avt {
      position: relative;

      img {
        padding: 0 15px;
      }

      &::before {
        position: absolute;
        content: "";
        top: -15px;
        left: 0;
        width: 250px;
        /* Chiều rộng của div */
        height: 250px;
        /* Chiều cao của div */
        z-index: -1;
        background: linear-gradient(135deg,
            white 25%,
            #daebff 25%,
            #daebff 50%,
            white 50%,
            white 75%,
            #daebff 75%);
        background-size: 40px 40px;
        /* Kích thước của các sọc */
      }

      &::after {
        position: absolute;
        content: "";
        bottom: -15px;
        right: 0;
        width: 250px;
        /* Chiều rộng của div */
        height: 250px;
        /* Chiều cao của div */
        z-index: -1;
        background-color: #cb6ce6;
      }
    }

    .bbl-media-infor-podcast {
      padding: 15px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .btn-podcast {
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
      color: white;
      background-color: #c9a6d3;
      font-size: 24px;
      border-radius: 40px;
      padding: 10px 30px;

      &:hover {
        opacity: 0.5;
      }
    }

    .bbl-media-infor-news {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      background-color: #e0c5e8;
      padding: 15px;
    }

    .btn-news {
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
      color: white;
      background-color: #c9a6d3;
      font-size: 16px;
      border-radius: 40px;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .bbl-form {
    position: relative;
    height: 700px;
    display: flex;
    align-items: center;

    h1 {
      border-radius: 50px;
      background-color: rgba(195, 27, 242, 0.57);
      box-shadow: 0 8px 32px 0 rgba(195, 27, 242, 0.27);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      padding: 20px;
      color: white;
      text-transform: uppercase;
    }
  }

  .footer {
    height: 100vh;
  }

  .icon-wrapper {
    text-align: center;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    gap: 80px;
    margin-top: 20px;
  }

  .icon-yt {
    font-size: 80px;
    cursor: pointer;
    transition: color 0.3s ease;
    color: red;
  }

  .icon-spot {
    font-size: 80px;
    cursor: pointer;
    transition: color 0.3s ease;
    color: green;
  }

  .icon-yt:hover {
    color: #808080;
  }

  .icon-spot:hover {
    color: #808080;
  }

  .icon-description {
    font-size: 20px;
    color: #000000;
    margin-top: 5px;
    font-weight: bold;
  }

  @media screen and (max-width: 1025px) {

    .flower-petal,
    .center-circle {
      width: 120px;
      height: 120px;
    }

    .online-container {
      flex-direction: column;
    }

    .headline-h1 {
      font-size: 42px !important;
    }

    .headline-h2 {
      font-size: 28px !important;
    }

    .headline-h3 {
      font-size: 24px !important;
    }

    .headline-h4 {
      font-size: 20px !important;
    }

    .bbl-trainerCoach {
      .bbl-trainerCoach-box {
        &:nth-child(1) {
          left: 5%;
        }

        &:nth-child(2) {
          right: 5%;
        }

        &:nth-child(3) {
          left: 0%;
        }

        &:nth-child(4) {
          right: 0%;
        }

        &:nth-child(5) {
          left: 5%;
        }

        &:nth-child(6) {
          right: 5%;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {

    .headline-h1 {
      font-size: 38px !important;
    }

    .headline-h2 {
      font-size: 24px !important;
    }

    .headline-h3 {
      font-size: 20px !important;
    }

    .headline-h4 {
      font-size: 16px !important;
    }

    .bbl-banner {
      height: 300px !important;

      .parallax-banner {
        background-size: 100% 100%;
      }
    }

    .bbl-header {
      .header-description {
        // padding: 0 20px;
        text-align: justify;
      }
    }

    .bbl-trainerCoach {
      .bbl-trainerCoach-box {
        width: 250px;

        &:nth-child(1) {
          left: 0%;
        }

        &:nth-child(2) {
          right: 0%;
        }

        &:nth-child(3) {
          left: -5%;
        }

        &:nth-child(4) {
          right: -5%;
        }

        &:nth-child(5) {
          left: 0%;
        }

        &:nth-child(6) {
          right: 0%;
        }
      }
    }
  }

  @media (min-width: 768px) {

    /* Apply styles for medium screens and above */
    .bbl-service {
      .bbl-service-cards-container {
        .child-full-height {
          display: flex;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {

    /* Adjust the size of the petals for mobile */
    .flower-petal,
    .center-circle {
      width: 80px;
      /* Smaller size for mobile */
      height: 80px;
    }

    .video-container iframe {
      width: 300px;
      height: 200px;
    }

    .questionaire-button {
      width: 40%;
    }


    /* Adjust text size for mobile */
    .headline-h2,
    .headline-h1 {
      font-size: 1.5rem;
      text-align: center;
      /* Center the text */
    }

    .blur-cover p {
      font-size: 1rem;
      /* Adjust text size for mobile */
    }

    .card {
      width: 150px;
    }

    .card-title {
      font-size: 0.8em;
      margin-bottom: 3px;
    }

    .card-content {
      font-size: 0.6em;
      color: #333;
    }

    .headline-h1 {
      font-size: 26px !important;
    }

    .headline-h2 {
      font-size: 22px !important;
    }

    .headline-h3 {
      font-size: 18px !important;
    }

    .headline-h4 {
      font-size: 16px !important;
    }

    .bbl-trainer-coach {
      .bbl-trainer-coach-card {
        &:nth-child(3n + 2) {
          top: 0px !important;
        }

        div {
          font-size: 18px;
          line-height: 20px;
        }
      }

      .bbl-trainerCoach-img {
        opacity: 0.5;
      }

      .bbl-trainerCoach-box {
        width: 250px;
        transform: translateX(-50%);
        font-size: 12px;

        &:nth-child(1) {
          top: 19%;
          left: 50%;
        }

        &:nth-child(2) {
          top: 29%;
          left: 50%;
        }

        &:nth-child(3) {
          top: 39%;
          left: 50%;
        }

        &:nth-child(4) {
          top: 52%;
          left: 50%;
        }

        &:nth-child(5) {
          top: 65%;
          left: 50%;
        }

        &:nth-child(6) {
          top: 78%;
          left: 50%;
        }
      }
    }

    .register-button {
      transform: translateY(-30px);
    }

    .bbl-service {
      .bbl-service-cards {
        .bbl-service-content {
          transform: translateY(-50px);
          margin: 0 15px;
          border-radius: 20px;
          padding: 15px 15px;

          .headline-h3 {
            padding: 15px;
          }

          .box-price {
            padding: 10px 20px;
          }

          ul {
            padding-left: 0;
            list-style-type: none;

            li {
              font-weight: bold;
            }
          }

          .price-default {
            font-size: 18px;
            line-height: 22px;
            margin-right: 10px;
          }

          .price-promo {
            font-size: 22px;
            line-height: 26px;
          }
        }
      }
    }

    .bbl-media-infor {
      .headline-h1 {
        &::after {
          left: 0;
          top: -30px;
        }

        &::before {
          left: 0;
          top: -30px;
        }
      }
    }
  }

  .popup {
    position: absolute;
    background: white;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    pointer-events: none;
    /* Ensure the popup doesn't interfere with mouse events */
    border-radius: 5px;
    /* Optional: Add rounded corners */
    font-size: 14px;
    /* Optional: Adjust font size */
    color: #333;
    /* Optional: Set text color */
    max-width: 200px;
    /* Optional: Set a maximum width */
    word-wrap: break-word;
    /* Ensure long words break to the next line */
  }

  .popup-price {
    color: purple;
    font-size: 2rem;
    font-weight: bolder;
  }
}

.popup-finish {
  display: flex;
  align-items: center;
  border-radius: 20px;
}