.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.auth-form {
    position: relative;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
}

.back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.auth-form h2 {
    margin-bottom: 20px;
    color: #333;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 15px;
}

.auth-input.invalid {
    border-color: red;
}

.auth-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.auth-button {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    color: white;
    background: linear-gradient(to right, #ff89fe, rgb(203, 108, 230));
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.auth-button:hover {
    background: linear-gradient(to right, rgb(203, 108, 230), #ff89fe);
}

.auth-text {
    margin-top: 15px;
    font-size: 14px;
    color: #333;
}

.auth-link {
    color: #ff89fe;
    text-decoration: none;
    font-weight: bold;
}

.auth-link:hover {
    text-decoration: underline;
}