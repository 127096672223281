.wrapper-input_text {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  label {
    font-size: 12px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
    margin-bottom: 0px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  input {
    padding: 10px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
  }

  select {
    padding: 13px 2px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
  }

  textarea {
    min-height: 150px;
    padding: 10px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
  }
}

.label {
  font-size: 20px;
  line-height: 1;
  color: #231f20;
  cursor: pointer;
  text-transform: unset;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
}

.input-text {
  margin-right: 5px;
}

.link-to_policy {
  color: #007bff;

  &:hover {
    color: #007bff;
  }
}
