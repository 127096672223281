html {
  box-sizing: border-box;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: $font-primary;
  padding: 0 !important;
}

input,
textarea,
select,
button {
  outline: none;
}

input {
  line-height: normal;
}

label,
button {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

p {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
}

input:disabled {
  cursor: not-allowed;
  background: #f8f9ff;
}
