.modal-register {
  display: flex;
  // height: 80vh !important;
  align-items: center;
}
.popup {
  border-radius: 20px;
  background-color: wheat;
}

.header-dropdown {
  li:hover {
    background: #457fca; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #5691c8,
      #457fca
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #5691c8,
      #457fca
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 10px;
    transition: all 0.3s;

    > a {
      color: #fff !important;
    }
  }
  li:hover > ul {
    display: block;
    transition: all 0.6s;
  }
}

.header-dropdown {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.header-dropdown li {
  position: relative;
}

.header-dropdown li a {
  color: white !important;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: block;
  padding: 10px;
  transition: all 0.3s;
}

.header-dropdown li ul {
  background: white;
  // background: #457fca; /* fallback for old browsers */
  // background: -webkit-linear-gradient(
  //   to right,
  //   #5691c8,
  //   #457fca
  // ); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(
  //   to right,
  //   #5691c8,
  //   #457fca
  // ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  position: absolute;
  top: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  line-height: normal;
  min-width: 160px;
  transition: all 0.3s;
}

.header-dropdown li ul li a {
  text-align: left;
  font-size: 14px;
  padding: 15px;
  display: block;
  white-space: nowrap;
  word-spacing: 2px;
}

.header-dropdown li ul li a:hover {
  background-color: #457fca;
  color: #fff !important;
}

.header-dropdown li ul li ul {
  left: 100%;
  top: 0;
  transition: all 0.6s;
}
