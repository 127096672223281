.wrapperFooter {
  // background-image: radial-gradient(ellipse at left,#3782c4,#3782c4,#67c8ec);
  //   background-image: linear-gradient(to bottom, black, #aa15ff);
  background-color: #aa15ff;
  color: #fff;

  .heading {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .heading2 {
    font-size: 20px;
    margin-top: 30px;
    font-weight: 600;
  }

  p {
    font-size: 15px;
    margin-bottom: 10px;
  }
}

.itemMenu {
  font-size: 15px;
  margin-bottom: 10px;

  &:hover {
    background: unset !important;
  }
}

.itemMenu a {
  transition: color 0.3s;
  color: #fff;

  &:hover {
    color: #d1edf3 !important;
  }
}

.wrapperInfo {
  background: transparent;
  color: #fff;
  padding: 15px 0;
  border-top: 1px solid white;
  font-size: 12px;
}

@media screen and (max-width: 992px) {
  .wrapperFooter {
    padding: 30px 0;
  }
}
