.buttonTopPosition {
  position: fixed;
  bottom: 80px;
  right: 35px;
  z-index: 99999 !important;
}

.buttonColor {
  color: #333 !important;
  background-color: #fff !important;
}
