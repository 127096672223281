.quiz {
    /* Box styles */
    .question-for-user-box {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s ease;
    }
    /* Visible state */
    .visible .question-for-user-box {
      animation: expandBox 0.5s ease forwards;
      opacity: 1;
      visibility: visible;
    }
    .hidden .question-for-user-box {
      animation: collapseBox 0.5s ease forwards;
      opacity: 0;
    }
    @keyframes expandBox {
      0% {
        max-height: 0;
      }
      100% {
        max-height: 200px; /* Set the expected height or a large enough value */
      }
    }
  
    /* Keyframe for collapsing */
    @keyframes collapseBox {
      0% {
        max-height: 200px;
      }
      100% {
        max-height: 0;
      }
    }
  }